import * as React from 'react';
import Image from 'next/image';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import mapIcon01 from '../../public/popup-mapicon01.png';
import mapIcon02 from '../../public/popup-mapicon02.png';
import { Box, Paper, Typography } from '@mui/material';
import { NextLink } from '@/components/link/NextLink';

interface SearchDialogProps {
  searchType?: string;
  searchCategory?: string;
  prefecture: string;
  isOpen: boolean;
  handleClose: () => void;
}

export function SearchDialog(props: SearchDialogProps) {
  const typePath = props.searchType ? `/${props.searchType}` : '';
  const categoryPath = props.searchCategory ? `/${props.searchCategory}` : '';

  return (
    <Box
      sx={{
        display: props.isOpen ? 'block' : 'none',
        width: '100%',
        height: '100%',
        position: 'relative',
        bgcolor: 'rgba(255, 255, 255, 0.8)',
        zIndex: '50',
      }}
    >
      <Paper
        square
        sx={{
          display: props.isOpen ? 'block' : 'none',
          position: 'absolute',
          padding: 5.5,
          bgcolor: 'primary.light',
          top: '50%',
          left: '50%',
          transform: `translate(-50%, -50%)`,
        }}
      >
        <Button
          sx={{ position: 'absolute', right: '-36px', top: '-36px' }}
          onClick={props.handleClose}
        >
          <CancelIcon
            sx={{
              fontSize: '60px',
              color: 'accent.main',
              bgcolor: '#ffffff',
              borderRadius: '100%',
            }}
          />
        </Button>
        <Box sx={{ display: 'flex', gap: 5 }}>
          <Button
            component={NextLink}
            href={`${typePath}${categoryPath}/${props.prefecture}/area`}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <Image src={mapIcon01} width="148" height="148" alt="地域から探す" />
            <Typography sx={{ color: '#ffffff', fontSize: '18px', fontWeight: 'bold' }}>
              地域から探す
            </Typography>
          </Button>
          <Button
            component={NextLink}
            href={`${typePath}${categoryPath}/${props.prefecture}/line`}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <Image src={mapIcon02} width="148" height="148" alt="沿線/駅から探す" />
            <Typography sx={{ color: '#ffffff', fontSize: '18px', fontWeight: 'bold' }}>
              沿線/駅から探す
            </Typography>
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
