import { Box, Grid, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { LinkListItem } from '../../components/list/LinkListRow';
import {
  hokkaidoArea,
  tohokuArea,
  hokurikuArea,
  shutokenArea,
  tokaiArea,
  kinkiArea,
  shikokuArea,
  kyushuArea,
} from '../../services/data/prefectureLinkList';
import { SearchDialog } from './SearchDialog';

const MapSearchBox = styled(Box)(({ theme }) => ({
  border: '2px solid',
  borderColor: theme.palette.primary.main,
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  padding: '9px 7px 2px 7px',
  position: 'absolute',
  '&::after': {
    position: 'absolute',
    display: 'block',
    content: '""',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderColor: 'transparent',
  },
}));

interface Props {
  wide?: boolean;
  type?: string;
  category?: string;
  isArea?: boolean;
  isLine?: boolean;
}

export const SearchByPrefectureMap: React.VFC<Props> = ({
  wide,
  type,
  category,
  isArea,
  isLine,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPrefecture, setSelectedPrefecture] = useState('');
  const getLinkComponent = (item: LinkListItem, wide?: boolean) => {
    if (isArea || isLine) {
      const href = `/${category}${item.href}${isArea ? '/area' : '/line'}`;
      return (
        <Link href={href}>
          <Typography variant="body2">{item.title}</Typography>
        </Link>
      );
    }

    return wide ? (
      <Link
        onClick={() => {
          setSelectedPrefecture(item.href.replace('/', ''));
          setIsOpen(true);
        }}
        sx={{ '&:hover': { cursor: 'pointer' } }}
      >
        <Typography variant="body2">{item.title}</Typography>
      </Link>
    ) : (
      <Link href={item.href}>
        <Typography variant="body2">{item.title}</Typography>
      </Link>
    );
  };

  return (
    <>
      <Box
        sx={{
          backgroundImage: wide ? `url('/maplink-wide.png')` : `url('/maplink.png')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          width: wide ? '1000px' : '732px',
          height: '465px',
          position: 'relative',
        }}
      >
        <MapSearchBox
          sx={{
            top: '9%',
            right: wide ? '36%' : '33%',
            minWidth: '125px',
            '&::after': {
              top: '21px',
              right: '-21px',
              borderWidth: '8px 0 8px 19px',
              borderLeftColor: 'primary.main',
            },
          }}
        >
          <Typography variant="h4" fontSize="16px" textAlign="center" fontWeight="bold">
            {hokkaidoArea.area}
          </Typography>
          <Grid container justifyContent="center">
            {hokkaidoArea.prefectureListItems.map((item, index) => (
              <Grid item xs="auto" key={`search-map-item-hokkaido${index}`} sx={{ px: 1, pb: 0.5 }}>
                {getLinkComponent(item, wide)}
              </Grid>
            ))}
          </Grid>
        </MapSearchBox>
        <MapSearchBox
          sx={{
            top: '30%',
            right: wide ? '16%' : '5%',
            maxWidth: '151px',
            '&::after': {
              top: '36px',
              left: '-21px',
              borderWidth: '8px 19px 8px 0',
              borderRightColor: 'primary.main',
            },
          }}
        >
          <Typography variant="h4" fontSize="16px" textAlign="center" fontWeight="bold">
            {tohokuArea.area}
          </Typography>
          <Grid container justifyContent="center">
            {tohokuArea.prefectureListItems.map((item, index) => (
              <Grid item xs="auto" key={`search-map-item-tohoku${index}`} sx={{ px: 1, pb: 0.5 }}>
                {getLinkComponent(item, wide)}
              </Grid>
            ))}
          </Grid>
        </MapSearchBox>
        <MapSearchBox
          sx={{
            top: '52%',
            right: wide ? '19%' : '8.5%',
            maxWidth: '151px',
            '&::after': {
              top: '36px',
              left: '-21px',
              borderWidth: '8px 19px 8px 0',
              borderRightColor: 'primary.main',
            },
          }}
        >
          <Typography variant="h4" fontSize="16px" textAlign="center" fontWeight="bold">
            {shutokenArea.area}
          </Typography>
          <Grid container justifyContent="center">
            {shutokenArea.prefectureListItems.map((item, index) => (
              <Grid item xs="auto" key={`search-map-item-shutoken${index}`} sx={{ px: 1, pb: 0.5 }}>
                {getLinkComponent(item, wide)}
              </Grid>
            ))}
          </Grid>
        </MapSearchBox>
        <MapSearchBox
          sx={{
            top: wide ? '29%' : '27%',
            right: wide ? '36%' : '32%',
            maxWidth: '151px',
            '&::after': {
              bottom: '-21px',
              left: '62px',
              borderWidth: '19px 8px 0 8px',
              borderTopColor: 'primary.main',
            },
          }}
        >
          <Typography variant="h4" fontSize="16px" textAlign="center" fontWeight="bold">
            {hokurikuArea.area}
          </Typography>
          <Grid container justifyContent="center">
            {hokurikuArea.prefectureListItems.map((item, index) => (
              <Grid item xs="auto" key={`search-map-item-hokuriku${index}`} sx={{ px: 1, pb: 0.5 }}>
                {getLinkComponent(item, wide)}
              </Grid>
            ))}
          </Grid>
        </MapSearchBox>
        <MapSearchBox
          sx={{
            top: '70%',
            right: wide ? '37%' : '32%',
            maxWidth: '112px',
            '&::after': {
              top: '-21px',
              left: '24px',
              borderWidth: '0 8px 19px 8px',
              borderBottomColor: 'primary.main',
            },
          }}
        >
          <Typography variant="h4" fontSize="16px" textAlign="center" fontWeight="bold">
            {tokaiArea.area}
          </Typography>
          <Grid container justifyContent="center">
            {tokaiArea.prefectureListItems.map((item, index) => (
              <Grid item xs="auto" key={`search-map-item-tokai${index}`} sx={{ px: 1, pb: 0.5 }}>
                {getLinkComponent(item, wide)}
              </Grid>
            ))}
          </Grid>
        </MapSearchBox>
        <MapSearchBox
          sx={{
            top: '70%',
            right: wide ? '49%' : '48.5%',
            maxWidth: '124px',
            '&::after': {
              top: '-21px',
              left: '84px',
              borderWidth: '0 8px 19px 8px',
              borderBottomColor: 'primary.main',
            },
          }}
        >
          <Typography variant="h4" fontSize="16px" textAlign="center" fontWeight="bold">
            {kinkiArea.area}
          </Typography>
          <Grid container justifyContent="center">
            {kinkiArea.prefectureListItems.map((item, index) => (
              <Grid item xs="auto" key={`search-map-item-kinki${index}`} sx={{ px: 1, pb: 0.5 }}>
                {getLinkComponent(item, wide)}
              </Grid>
            ))}
          </Grid>
        </MapSearchBox>
        <MapSearchBox
          sx={{
            top: '31%',
            right: wide ? '52%' : '54%',
            maxWidth: '151px',
            '&::after': {
              bottom: '-21px',
              left: '88px',
              borderWidth: '19px 8px 0 8px',
              borderTopColor: 'primary.main',
            },
          }}
        >
          <Typography variant="h4" fontSize="16px" textAlign="center" fontWeight="bold">
            {shikokuArea.area}
          </Typography>
          <Grid container justifyContent="center">
            {shikokuArea.prefectureListItems.map((item, index) => (
              <Grid item xs="auto" key={`search-map-item-shikoku${index}`} sx={{ px: 1, pb: 0.5 }}>
                {getLinkComponent(item, wide)}
              </Grid>
            ))}
          </Grid>
        </MapSearchBox>
        <MapSearchBox
          sx={{
            top: '34%',
            right: wide ? '68%' : '75.5%',
            maxWidth: '151px',
            '&::after': {
              bottom: '-21px',
              left: '120px',
              borderWidth: '19px 8px 0 8px',
              borderTopColor: 'primary.main',
            },
          }}
        >
          <Typography variant="h4" fontSize="16px" textAlign="center" fontWeight="bold">
            {kyushuArea.area}
          </Typography>
          <Grid container justifyContent="center">
            {kyushuArea.prefectureListItems.map((item, index) => (
              <Grid item xs="auto" key={`search-map-item-kyushu${index}`} sx={{ px: 1, pb: 0.5 }}>
                {getLinkComponent(item, wide)}
              </Grid>
            ))}
          </Grid>
        </MapSearchBox>
        {wide && (
          <SearchDialog
            isOpen={isOpen}
            handleClose={() => setIsOpen(false)}
            searchType={type}
            searchCategory={category}
            prefecture={selectedPrefecture}
          />
        )}
      </Box>
    </>
  );
};
